<template lang="">
  <div>
      <!-- <b-button
        variant="success"
        class="mr-0"
        @click.prevent="showUpload()"
      >
      Upload Dokumen Baru
      </b-button>
      <hr> -->
     <div v-if="renderComp">
      <div class="form-group" v-for="(records, index) in data_field">
        <div class="row">
          <div class="col-md-5">
            <span class="mr-1"><feather-icon
                icon="FileTextIcon"
              /></span><a :href="records.file_url" target="_blank">{{ records.type.name }}</a>
          </div>
          <div class="col-md-1">
            <b-button
                variant="danger"
                class="mr-0"
                title="Hapus"
                @click.prevent="confirmDelete(records)"
              >
              <feather-icon
                icon="TrashIcon"
              />
              </b-button>
          </div>
        </div>
        <hr>
      </div>
     </div> 
      <b-modal
        id="modal-center"
        ref="my-modal-form"
        :title="title_modal"
        centered
        hide-footer
      >
      <div class="form-group">
        <label>Pilih Jenis Dokumen</label>
        <select class="form-control" v-model="data_form.file_type_id">
          <option value="">Pilih Jenis Dokumen</option>
          <option v-for="(records, index) in data_doc" :value="records.id">{{ records.name }}</option>
        </select>
        <label>Pilih File</label>

        <b-form-file
            v-model="datafile"
            id="extension"
            accept=".jpg, .png, .pdf, .docx, .xlsx"
            placeholder="Tidak ada file yang dipilih"
            @change="upload"
          />
      </div>

        <div class="form-group mt-3 float-right">
          <b-button class variant="outline-danger mr-1" @click="hideModal">Batal</b-button>
          <b-button variant="primary" @click.prevent="submitForm">Simpan</b-button>
        </div>
      </b-modal>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BModal,
  BFormFile,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BButton,
    BModal,
    BFormFile,
    BCard
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  mounted(){
    this.getDokumen()
    this.getFile()
  },
  data() {
    return {
      dataurl:this.posturl+"/"+this.$route.params.id+"/file",
      baseroute:"spb",
      title:"Dokumen",
      tombol_hide:true,
      title_modal : null,
      renderComp:true,
      data_doc:[],
      data_field:[],
      datafile:null,
      data_form:{
            file_type_id:'',
            file:null,
            data_file:null,
      },
      fields: [
        { key: 'area.name', label: 'Judul File', sortable: true },
        { key: 'name', label: 'Tipe File', sortable: true },
      ],
    }
  },
  methods:{
    getDokumen(){
      this.$http.get('/file-type').then(res=>{
        this.data_doc = res.data.data
      })
    },
    getFile(){
      this.$http.get(this.dataurl).then(res=>{
        this.data_field = res.data.data
      })
    },
    showUpload(){
      this.title_modal = "Upload Dokumen"
      this.$refs['my-modal-form'].show();
    },
    hideModal(){
      this.$refs['my-modal-form'].hide()
    },
    upload(e){
       let files = e.target.files[0]
       this.data_form.data_file = files
    },
    submitForm(){
      let formData = new FormData()
      formData.append('file',this.data_form.data_file)
      this.$http.post('/upload/image',formData).then(res=>{
        if(res.data.path != undefined){
          this.data_form.file = res.data.path
          this.$http.post('/spb/'+this.$route.params.id+'/file',this.data_form).then(()=>{
              this.getFile()
              this.$refs['my-modal-form'].hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Submit Success',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
          })
        }
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.type.name != null ? 'Anda Ingin Menghapus Data '+data.type.name +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+data.id)
              .then(() => {
                this.getFile()
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
  }
}
</script>
<style lang="">

</style>

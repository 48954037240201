<template>
  <div>
    <b-button
      v-b-modal="'modal-select-'+id"
      size="md"
      variant="success"
    >
      {{ value.length == 0 ? 'Pilih' : value.model }}
    </b-button>
    <b-modal
      :id="'modal-select-'+id"
      centered
      title="Pilih"
      hide-footer
    >
      <div><basetable
        :dataurl="dataurl"
        :fields="fields"
        :title="title"
        @onRowSelected="onRowSelected"
      /></div>
    </b-modal>
  </div>
</template>
<script>
import {BButton} from 'bootstrap-vue'
import basetable from '@/views/base/BaseSelectTable.vue'
export default {
  components: {
    basetable,
    BButton,
    
  },
  props:{
    value:{
      type: String | Number | Boolean | Object,
      default: function () {
        return ''
      }
    },
    id: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    datavalue:{
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      fields: [
        { key: 'model', label: 'name' },
      ],
    }
  },
  mounted() {
    // console.log(this.datavalue)
    // this.labeldata = this.datavalue
  },
  methods:{
    onRowSelected(val) {
      this.$bvModal.hide('modal-select-'+this.id)
      this.$emit('input', val)
      // this.$emit('updateLabel', val)
    },
  }
}
</script>
<style lang="">
  
</style>
<template lang="">
  <div v-if="showTable">
      <basetable
        v-if="renderComp"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :tombol_hide="tombol_hide"
        :search_hide="search_hide"
        :page_size_hide="true"
        :no_margin="true"
        :custom_action="false"
      >          
      <!-- <template v-slot:buttonaddon>
        <b-button
          v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? false : true"
          class="mb-1"
          variant="success"
          type="button"
          @click.prevent="tampilForm()"
        >
          Tambah Item Barang {{item_id}}
        </b-button>
        <span v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? true : false"></span>
      </template> -->
        <template #cell(duration)="data">
          {{data.item.duration}}
          <span v-if="data.item.duration_unit == 'day'">Hari</span>
          <span v-else-if="data.item.duration_unit == 'month'">Bulan</span>
          <span v-else>Tahun</span>
        </template>
        <template #cell(action)="data">
            <b-button
                v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered'  || model.status == 'received' || model.status == 'distributed') ? false : true"
                variant="primary"
                size="sm"
                class="mr-1"
                @click="editForm(data)"
              >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <span v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered'  || model.status == 'received' || model.status == 'distributed') ? true : false">&nbsp;</span>
            <b-button
                v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered'  || model.status == 'received' || model.status == 'distributed') ? false : true"
                variant="warning"
                size="sm"
                class="mr-0"
                @click="confirmDelete(data)"
              >
              <feather-icon
                icon="TrashIcon"
              />
              </b-button>
        </template>
      </basetable>
  </div>
  <div v-else class="m-1 mt-3">
    <h3 class="mb-2">{{form_title}}</h3>
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Jenis Varian</label>
          </div>
          <div class="col-md-5">
            <template>
              <baseSelectSingle
                  :id="'variant'"
                  v-model="fields_form.variant"
                  :dataurl="'/vehicle-variant'"
                  :title="'Jenis Varian'"
                />
            </template>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Uraian</label>
          </div>
          <div class="col-md-9">
              <input type="text" placeholder="Uraian" v-model="fields_form.description" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">TOD</label>
          </div>
          <div class="col-md-3">
              <input type="number" placeholder="TOD" v-model="fields_form.tod" min="1" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Jumlah Item</label>
          </div>
          <div class="col-md-3">
              <input type="number" placeholder="Jumlah Item" v-model="fields_form.qty" min="1" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Lama Sewa</label>
          </div>
          <div class="col-md-3">
              <input type="number" placeholder="Lama Sewa" v-model="fields_form.duration" min="1" class="form-control" required="">
          </div>
          <div class="col-md-4">
            <select class="form-control" v-model="fields_form.duration_unit" required="">
              <option value="day">Hari</option>
              <option value="month">Bulan</option>
              <option value="year">Tahun</option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Harga Satuan</label>
          </div>
          <div class="col-md-6">
              <input type="number" placeholder="Harga Satuan" v-model="fields_form.price" min="0" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Harga Satuan</label>
          </div>
          <div class="col-md-5">
            <select class="form-control" v-model="fields_form.is_tax" required="">
              <option value="true">Termasuk Pajak</option>
              <option value="false">Belum Termasuk Pajak</option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Harga Satuan</label>
          </div>
          <div class="col-md-9">
            <textarea class="form-control" v-model="fields_form.note" placeholder="Keterangan" required=""></textarea>
          </div>
        </div>
      </div>
        <b-button
            class="mr-1"
            size="md"
            variant="primary"
            type="submit"
            @click.prevent="btnSimpan()"
          >
            <span>Simpan</span>
          </b-button>
          <b-button
            class="mr-1"
            size="md"
            variant="outline-secondary"
            type="button"
            @click.prevent="tampilTable()"
          >
            <span>Batal</span>
          </b-button>
    </form>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleDataV2.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    baseSelectSingle,
    BButton,
    BCard
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataurl: this.posturl+"/"+this.$route.params.id+"/item",
      baseroute:"spb",
      title:"",
      form_title:null,
      tombol_hide:true,
      search_hide: true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'variant.model', label: 'Nama Barang', sortable: true },
        { key: 'description', label: 'Uraian', sortable: true },
        { key: 'tod', label: 'TOD', sortable: true },
        { key: 'variant.code', label: 'Merk', sortable: true },
        { key: 'qty', label: 'Jumlah', sortable: true },
        { key: 'duration', label: 'Lama Sewa', sortable: true },
        { key: 'price', label: 'Harga Satuan', sortable: true },
      ],
      fields_form :{
            variant:[],
            variant_id:null,
            description:null,
            tod:1,
            qty:1,
            duration:0,
            duration_unit:'day',
            price:null,
            is_tax:true,
            note:null,
      },
      model:{}, 
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          this.model = res.data
        })
     },
   tampilForm(){
    this.showTable = false
    this.form_title = "Tambah Item Barang"
    this.valid_form = 'tambah'
    this.resetForm()
   },
   tampilTable(){
    this.showTable = true
   },
   btnSimpan(){
        this.filterDataSubmit()
        if(this.valid_form == 'tambah'){
          this.$http.post('/spb/'+this.$route.params.id+'/item',this.fields_form).then(()=>{
          this.resetForm()
            this.showTable = true
          })
        }else{
          this.$http.put('/spb/'+this.$route.params.id+'/item/'+this.id_item,this.fields_form).then(()=>{
            this.resetForm()
            this.showTable = true
          })
        }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
   },
   editForm(data){
    this.fields_form.variant = data.item.variant
    this.fields_form.description = data.item.description
    this.fields_form.tod = data.item.tod
    this.fields_form.qty = data.item.qty
    this.fields_form.duration = data.item.duration
    this.fields_form.duration_unit = data.item.duration_unit
    this.fields_form.price = data.item.price
    this.fields_form.is_tax = data.item.is_tax
    this.fields_form.note = data.item.note
    this.showTable = false
    this.form_title = "Edit Item Barang"
    this.valid_form = 'edit'
    this.id_item = data.item.id
   },
   confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm(data.item.variant != null ? 'Anda Ingin Menghapus Data '+data.item.variant.model +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                });
            })
          }
        })
    },
    resetForm(){
      this.fields_form = {
            variant:[],
            variant_id:null,
            description:null,
            tod:1,
            qty:1,
            duration:0,
            duration_unit:'day',
            price:null,
            is_tax:true,
            note:null,
      }
    },
   async filterDataSubmit() {
      let newData ={}
      // console.log(this.model)
        if(typeof this.fields_form.variant === 'object'){
          if(Array.isArray(this.fields_form.variant)){
            let arrData = this.fields_form.variant.map(e => e.id)
            newData[this.fields_form.variant] = arrData
          }else{
            newData['variant_id'] = this.fields_form.variant.id
            this.fields_form.variant_id = this.fields_form.variant.id
          }
        }
    },
  }
}
</script>
<style lang="">

</style>

<template lang="">
  <div v-if="showTable">
      <basetable
        v-if="renderComp"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :tombol_hide="tombol_hide"
        :search_hide="true"
        :page_size_hide="true"
        :no_margin="true"
        :custom_action="false"
      >
      <!-- <template v-slot:buttonaddon>
        <b-button
          class="mb-1"
          variant="success"
          type="button"
          @click.prevent="tampilForm()"
        >
          Tambah Item Barang 
        </b-button>
      </template> -->
        <template #cell(duration)="data">
          {{data.item.duration}}
          <span v-if="data.item.duration_unit == 'day'">Hari</span>
          <span v-else-if="data.item.duration_unit == 'month'">Bulan</span>
          <span v-else>Tahun</span>
        </template>
        <template #cell(action)="data">
            <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="editForm(data)"
              >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
              <b-button
                variant="warning"
                size="sm"
                class="mr-1"
                @click="confirmDelete(data)"
              >
              <feather-icon
                icon="TrashIcon"
              />
              </b-button>
            <!-- <b-button
                variant="info"
                size="sm"
                class="mr-0"
                @click="showDistribute"
              >
              <feather-icon
                icon="CheckIcon"
              />
              </b-button> -->
        </template>
      </basetable>
      <b-modal
          id="modal-center"
          ref="my-modal-distribute"
          :title="title_modal"
          centered
          hide-footer
        >
        <div class="form-group">
          <p>Apa anda ingin mendistibusikan SPB ini ke vendor?</p>
        </div>

          <div class="form-group mt-2 float-right">
            <b-button class variant="primary mr-1" @click="hideModal">Batal</b-button>
            <b-button variant="warning" @click.prevent="submitDistribute">Kirim Distribusi</b-button>
          </div>
        </b-modal>
  </div>
  <div v-else class="m-1 mt-3">
    <h3 class="mb-2">{{form_title}}</h3>
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Jenis Varian</label>
          </div>
          <div class="col-md-5">
            <template>
              <baseSelectSingle
                  :id="'variant'"
                  v-model="fields_form.variant"
                  :dataurl="'/vehicle-variant'"
                  :title="'Jenis Varian'"
                />
            </template>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">NOPOL</label>
          </div>
          <div class="col-md-3">
              <input type="text" placeholder="NOPOL" v-model="fields_form.number_plate" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Tahun</label>
          </div>
          <div class="col-md-3">
              <input type="number" placeholder="Tahun" v-model="fields_form.year" min="1" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Warna</label>
          </div>
          <div class="col-md-4">
              <input type="text" placeholder="Warna" v-model="fields_form.color" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Nomor STNK</label>
          </div>
          <div class="col-md-5">
              <input type="text" placeholder="Nomor STNK" v-model="fields_form.stnk_number" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Nama STNK</label>
          </div>
          <div class="col-md-5">
              <input type="text" placeholder="Nama STNK" v-model="fields_form.stnk_name" min="0" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Tanggal STNK</label>
          </div>
          <div class="col-md-4">
              <flat-pickr
                v-model="fields_form.stnk_date"
                class="form-control"
              />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">No Rangka</label>
          </div>
          <div class="col-md-5">
              <input type="text" placeholder="No Rangka" v-model="fields_form.frame_number" min="0" class="form-control" required="">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">No Mesin</label>
          </div>
          <div class="col-md-5">
              <input type="text" placeholder="No Mesin" v-model="fields_form.machine_number" min="0" class="form-control" required="">
          </div>
        </div>
      </div>
        <b-button
            class="mr-1"
            size="md"
            variant="primary"
            type="submit"
            @click.prevent="btnSimpan()"
          >
            <span>Simpan</span>
          </b-button>
          <b-button
            class="mr-1"
            size="md"
            variant="outline-secondary"
            type="button"
            @click.prevent="tampilTable()"
          >
            <span>Batal</span>
          </b-button>
    </form>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleDataV2.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

import {
  BCard,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    baseSelectSingle,
    flatPickr,
    BButton,
    BCard
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataurl: this.posturl+"/"+this.$route.params.id+"/vehicle",
      baseroute:"spb",
      title:"",
      form_title:null,
      tombol_hide:true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'variant.model', label: 'Nama Barang', sortable: true },
        { key: 'number_plate', label: 'NOPOL', sortable: true },
        { key: 'year', label: 'Tahun', sortable: true },
        { key: 'color', label: 'Warna', sortable: true },
        { key: 'status', label: 'Distribusi', sortable: true },
      ],
      fields_form :{
            variant:[],
            variant_id:null,
            number_plate:null,
            year:2021,
            color:null,
            stnk_number:null,
            stnk_name:null,
            stnk_date:null,
            frame_number:null,
            machine_number:null,
      },
    }
  },
  methods:{
   tampilForm(){
    this.showTable = false
    this.form_title = "Tambah Item Kendaraan"
    this.valid_form = 'tambah'
    this.resetForm()
   },
   tampilTable(){
    this.showTable = true
   },
   btnSimpan(){
        this.filterDataSubmit()
        if(this.valid_form == 'tambah'){
          this.$http.post('/spb/'+this.$route.params.id+'/vehicle',this.fields_form).then(()=>{
          this.resetForm()
            this.showTable = true
          })
        }else{
          this.$http.put('/spb/'+this.$route.params.id+'/vehicle/'+this.id_item,this.fields_form).then(()=>{
            this.resetForm()
            this.showTable = true
          })
        }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
   },
   showDistribute(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-distribute'].show();
    },
    hideModal(){
      this.$refs['my-modal-distribute'].hide();
    },
    submitDistribute(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/distribute').then(()=>{
          this.$refs['my-modal-distribute'].hide();
          this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB dikirim ke vendor',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
   editForm(data){
    this.fields_form.variant = data.item.variant
    this.fields_form.number_plate = data.item.number_plate
    this.fields_form.year = data.item.year
    this.fields_form.color = data.item.color
    this.fields_form.stnk_number = data.item.stnk_number
    this.fields_form.stnk_name = data.item.stnk_name
    this.fields_form.stnk_date = data.item.stnk_date
    this.fields_form.frame_number = data.item.frame_number
    this.fields_form.machine_number = data.item.machine_number
    this.showTable = false
    this.form_title = "Edit Item Kendaraan"
    this.valid_form = 'edit'
    this.id_item = data.item.id
   },
   confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm(data.item.variant != null ? 'Anda Ingin Menghapus Data '+data.item.variant.model +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                });
            })
          }
        })
    },
    resetForm(){
      this.fields_form = {
            variant:[],
            variant_id:null,
            number_plate:null,
            year:2021,
            color:null,
            stnk_number:null,
            stnk_name:null,
            stnk_date:null,
            frame_number:null,
            machine_number:null,
      }
    },
   async filterDataSubmit() {
      let newData ={}
      // console.log(this.model)
        if(typeof this.fields_form.variant === 'object'){
          if(Array.isArray(this.fields_form.variant)){
            let arrData = this.fields_form.variant.map(e => e.id)
            newData[this.fields_form.variant] = arrData
          }else{
            newData['variant_id'] = this.fields_form.variant.id
            this.fields_form.variant_id = this.fields_form.variant.id
          }
        }
    },
  }
}
</script>
<style lang="">

</style>
